import { AdminDetailUser } from '@core/models/user/admin-detail-user';
import { GetUserResponseDto } from '@core/dtos/id-api/users/get-user.response-dto';

export function getUserResponseMapper(getUserResponseDto: GetUserResponseDto): AdminDetailUser {
  return {
    uuid: getUserResponseDto.user.uuid,
    accountStatus: getUserResponseDto.user.accountStatus,
    isActive: getUserResponseDto.user.accountStatus === 'ACTIVATED',
    firstName: getUserResponseDto.user.firstName,
    lastName: getUserResponseDto.user.lastName,
    nin: getUserResponseDto.user.nin,
    testUser: getUserResponseDto.user.testUser,
    emailAddresses: getUserResponseDto.user.emailAddresses.map((e) => {
      return {
        uuid: e.uuid,
        emailAddress: e.emailAddress,
        isValid: true,
        primary: e.primary,
        verifiedAt: !e.verifiedAt ? undefined : new Date(e.verifiedAt),
      };
    }),
    phoneNumbers: getUserResponseDto.user.phoneNumbers.map((p) => {
      return {
        uuid: p.uuid,
        phoneNumber: p.phoneNumber,
        isValid: true,
        primary: p.primary,
        verifiedAt: !p.verifiedAt ? undefined : new Date(p.verifiedAt),
      };
    }),
    postalAddresses: getUserResponseDto.user.postalAddresses.map((a) => {
      return {
        uuid: a.uuid,
        country: a.country,
        countryCode: a.countryCode,
        line1: a.line1,
        line2: !a.line2 ? undefined : a.line2,
        line3: !a.line3 ? undefined : a.line3,
        postalArea: a.postalArea,
        postalCode: a.postalCode,
      };
    }),
    verifications: getUserResponseDto.user.verifications.map((v) => {
      return {
        uuid: v.uuid,
        type: v.type,
        manual: v.manual,
        lastVerifiedAt: new Date(v.lastVerifiedAt),
      };
    }),
    isVerified: getUserResponseDto.user.verifications.length > 0,
    roles: getUserResponseDto.user.roles.map((r) => {
      return {
        uuid: r.uuid,
        role: r.role,
        name: r.name,
        description: r.description,
        children: !r.children ? undefined : r.children,
      };
    }),
  };
}
