import { PutUserRolesResponseDto } from '@core/dtos/id-api/users/put-user-roles.response-dto';
import { Role } from '@core/models/user/role';

export function putUserRolesResponseMapper(putUserRolesResponseDto: PutUserRolesResponseDto): Role[] {
  return putUserRolesResponseDto.roles.map((r) => {
    return {
      uuid: r.uuid,
      role: r.role,
    };
  });
}
