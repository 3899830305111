import { PutUserAccountStatusResponseDto } from '@core/dtos/id-api/users/put-user-account-status-response.dto';
import { UserAccountStatusChange } from '@core/models/user/user-account-status-change';

export function putUserAccountStatusChangeResponseMapper(putUserStatusResponseDto: PutUserAccountStatusResponseDto): UserAccountStatusChange {
  return {
    uuid: putUserStatusResponseDto.statusChange.uuid,
    fromStatus: putUserStatusResponseDto.statusChange.fromStatus,
    toStatus: putUserStatusResponseDto.statusChange.toStatus,
    reason: putUserStatusResponseDto.statusChange.reason,
  };
}
