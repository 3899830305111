import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserPasswordForm } from '@shared/components/user-password-form/form-types/user-password.form';

export function createUserPasswordForm(showCurrentPassword?: boolean): FormGroup<UserPasswordForm> {
  const fg: FormGroup<UserPasswordForm> = new FormGroup({
    password: new FormControl<string>(
      {
        value: '',
        disabled: false,
      },
      {
        validators: [Validators.required],
      }
    ),
    repeatPassword: new FormControl<string>(
      {
        value: '',
        disabled: false,
      },
      {
        validators: [Validators.required],
      }
    ),
  });

  if (showCurrentPassword) {
    fg.addControl('currentPassword', new FormControl(
      {
        value: '',
        disabled: false,
      },
      {
        validators: [Validators.required],
      }
    ));
  }

  return fg;
}
