@if (passwordForm.controls.currentPassword) {
  <ff-ngx-form-field>
    <label class="ff-label" for="current-password"> Eksisterende (gammelt) passord </label>
    <ff-ngx-input-container>
      <input
        ffNgxInput
        id="current-password"
        type="password"
        [formControl]="passwordForm.controls.currentPassword"
        #currentPasswordInputRef
        placeholder="Eksisterende passord"
        (keyup.enter)="savePassword()"
      />
      <ff-ngx-password-reveal [inputElement]="currentPasswordInputRef" ffNgxInputContainerSuffix />
    </ff-ngx-input-container>
    <ff-ngx-form-field-error>
      @if (passwordForm.controls.currentPassword.hasError('required')) {
        <span>Feltet må fylles ut.</span>
      } @else if (passwordForm.controls.currentPassword.hasError('wrong_password')) {
        <span>Feil passord.</span>
      }
    </ff-ngx-form-field-error>
  </ff-ngx-form-field>
}

<ff-ngx-form-field [class.expanded-error]="expandedError()">
  <label class="ff-label" for="password"> Nytt passord </label>
  <ff-ngx-input-container>
    <input
      ffNgxInput
      id="password"
      type="password"
      [formControl]="passwordForm.controls.password"
      #passwordInputRef
      placeholder="Personlig passord"
      (keyup.enter)="savePassword()"
    />
    <ff-ngx-password-reveal [inputElement]="passwordInputRef" ffNgxInputContainerSuffix />
  </ff-ngx-input-container>
  <ff-ngx-form-field-error>
    @if (passwordForm.controls.password.hasError('required')) {
      <span>Feltet må fylles ut.</span>
    } @else if (passwordForm.controls.password.hasError('too_short')) {
      <span>Passordet du valgte er for kort.</span>
    } @else if (passwordForm.controls.password.hasError('compromised')) {
      <span>Passordet du valgte er allerede blitt kompromittert i datalekkasje hos andre nettsider, og er derfor ikke trygt å bruke. Velg et annet.</span>
    }
  </ff-ngx-form-field-error>
</ff-ngx-form-field>

<ff-ngx-form-field>
  <label class="ff-label" for="repeat-password"> Gjenta nytt passord </label>
  <ff-ngx-input-container>
    <input
      ffNgxInput
      id="repeat-password"
      type="password"
      [formControl]="passwordForm.controls.repeatPassword"
      #repeatPasswordInputRef
      placeholder="Gjenta personlig passord"
      (keyup.enter)="savePassword()"
    />
    <ff-ngx-password-reveal [inputElement]="repeatPasswordInputRef" ffNgxInputContainerSuffix />
  </ff-ngx-input-container>
  <ff-ngx-form-field-error>
    @if (passwordForm.controls.repeatPassword.hasError('required')) {
      <span>Feltet må fylles ut.</span>
    } @else if (passwordForm.controls.repeatPassword.hasError('not_identical')) {
      <span>Må være likt passord.</span>
    }
  </ff-ngx-form-field-error>
</ff-ngx-form-field>

<button
  ffNgxButton
  (click)="savePassword()"
  [processing]="processing()"
  class="save-button"
>Lagre
</button>

<app-feedback-messages [messages]="feedbackMessages()" />
