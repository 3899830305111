import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { PendingInformationUpdates } from '@core/entities/login/pending-information-updates.entity';
import { SsnValidationResponseEntity } from '@core/entities/response/ssn-validation-response.entity';
import { EnvironmentHelper, EnvironmentHelper as EnvHelper } from '@core/helpers/environment.helper';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { deserialize } from 'serializr';
import idApiConfig from '@config/apis/id/id-api.config';
import { GetUsersResponseDto } from '@core/dtos/id-api/users/get-users.response-dto';
import { FfNgxUrlHelper } from '@fagforbundet/ngx-components';
import { PageableAdminListUsers } from '@core/models/user/pageable-admin-list-users';
import {
  getUsersPageableAdminListUsersResponseMapper,
} from '@core/mappers/response/id-api/users/get-users-pageable-admin-list-users.response-mapper';
import { AdminDetailUser } from '@core/models/user/admin-detail-user';
import { PutUserResponseDto } from '@core/dtos/id-api/users/put-user.response-dto';
import { PostUserRequestDto } from '@core/dtos/id-api/users/post-user.request-dto';
import { PostUserResponseDto } from '@core/dtos/id-api/users/post-user.response-dto';
import { postUserResponseMapper } from '@core/mappers/response/id-api/users/post-user.response-mapper';
import { GetUserResponseDto } from '@core/dtos/id-api/users/get-user.response-dto';
import { getUserResponseMapper } from '@core/mappers/response/id-api/users/get-user.response-mapper';
import { PutUserAccountStatusResponseDto } from '@core/dtos/id-api/users/put-user-account-status-response.dto';
import { PutUserAccountStatusRequestDto } from '@core/dtos/id-api/users/put-user-account-status-request.dto';
import { UserAccountStatusChange } from '@core/models/user/user-account-status-change';
import {
  putUserAccountStatusChangeResponseMapper,
} from '@core/mappers/response/id-api/users/put-user-account-status-change.response-mapper';
import { PutUserRequestDto } from '@core/dtos/id-api/users/put-user.request-dto';
import { putUserResponseMapper } from '@core/mappers/response/id-api/users/put-user.response-mapper';
import { DeleteUserResponseDto } from '@core/dtos/id-api/users/delete-user.response-dto';
import { PutUserRolesResponseDto } from '@core/dtos/id-api/users/put-user-roles.response-dto';
import { PutUserRolesRequestDto } from '@core/dtos/id-api/users/put-user-roles.request-dto';
import { putUserRolesResponseMapper } from '@core/mappers/response/id-api/users/put-user-roles.response-mapper';
import { Role } from '@core/models/user/role';
import { PatchUserPasswordRequestDto } from '@core/dtos/id-api/users/patch-user-password.request-dto';
import { PatchUserPasswordFeedback } from '@core/models/user/patch-user-password.feedback';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  #httpClient = inject(HttpClient);

  checkValidNin(nin: string): Observable<SsnValidationResponseEntity> {
    return this.#httpClient.get(EnvironmentHelper.fetchAPIBase('v1/validate/ssn/' + nin)).pipe(
      map((response: object) => {
        return deserialize(SsnValidationResponseEntity, response);
      }),
    );
  }

  deleteUser(userUuid: string): Observable<void> {
    return this.#httpClient.delete<DeleteUserResponseDto>(
      FfNgxUrlHelper.createUrl(
        idApiConfig.baseUrl,
        idApiConfig.endpoints.DELETE_USER.path,
        {
          userUuid,
        },
      ).toString(),
    ).pipe(
      map(() => {
        return undefined;
      }),
    );
  }

  existsBySsn(ssn: string): Observable<boolean> {
    return this.#httpClient
      .get(EnvHelper.fetchAPIBase('v1/users/exists/by-ssn/' + ssn))
      .pipe(map((response: { exists: boolean }) => response.exists));
  }

  /**
   * Fetches user
   *
   * NB! To fetch logged-in user, use getSelf()
   */
  getUser(userUuid: string, includeNin = false): Observable<AdminDetailUser> {
    const params = includeNin ? { include: 'nin' } : null;
    return this.#httpClient.get<GetUserResponseDto>(
      FfNgxUrlHelper.createUrl(
        idApiConfig.baseUrl,
        idApiConfig.endpoints.GET_USER.path,
        {
          userUuid,
        },
      ).toString(), { params },
    ).pipe(
      map((r) => {
        return getUserResponseMapper(r);
      }),
    );
  }

  getUsers(query?: string, role?: string, limit: number = 10, offset: number = 0): Observable<PageableAdminListUsers> {
    let params = new HttpParams();
    if (query) {
      params = params.set('query', query);
    }
    if (role) {
      params = params.set('role', role);
    }

    params = params.set('limit', limit);
    params = params.set('offset', offset);

    return this.#httpClient.get<GetUsersResponseDto>(
      FfNgxUrlHelper.createUrl(
        idApiConfig.baseUrl,
        idApiConfig.endpoints.GET_USERS.path,
      ).toString(),
      {
        params: params,
      },
    ).pipe(
      map((r) => {
        return getUsersPageableAdminListUsersResponseMapper(r);
      }),
    );
  }

  getPendingInformationUpdates(oauthClientId?: string): Observable<PendingInformationUpdates> {
    let params = new HttpParams();

    if (oauthClientId) {
      params = params.set('client_id', oauthClientId);
    }

    return this.#httpClient
      .get(EnvironmentHelper.fetchAPIBase('v1/users/pending-user-info-updates'), {
        params,
      })
      .pipe(
        map((response: { userInfo: object }) => {
          return deserialize(PendingInformationUpdates, response.userInfo);
        }),
      );
  }

  postUser(postUserRequestDto: PostUserRequestDto): Observable<AdminDetailUser> {
    return this.#httpClient
      .post<PostUserResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.POST_USER.path,
        ).toString(), postUserRequestDto)
      .pipe(
        map((r) => {
          return postUserResponseMapper(r);
        }),
      );
  }

  putUser(userUuid: string, putUserRequestDto: PutUserRequestDto): Observable<AdminDetailUser> {
    let params = new HttpParams();
    params = params.set('include', 'nin');

    return this.#httpClient
      .put<PutUserResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.PUT_USER.path,
          {
            userUuid,
          },
        ).toString(),
        putUserRequestDto,
        { params },
      )
      .pipe(
        map((r) => {
          return putUserResponseMapper(r);
        }),
      );
  }

  putUserStatus(userUuid: string, putUserStatusRequestDto: PutUserAccountStatusRequestDto): Observable<UserAccountStatusChange> {
    return this.#httpClient
      .put<PutUserAccountStatusResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.PUT_USER_STATUS.path,
          {
            userUuid,
          },
        ).toString(), putUserStatusRequestDto)
      .pipe(
        map((r) => {
          return putUserAccountStatusChangeResponseMapper(r);
        }),
      );
  }

  putRoles(userUuid: string, putUserRolesRequestDto: PutUserRolesRequestDto): Observable<Role[]> {
    return this.#httpClient
      .put<PutUserRolesResponseDto>(
        FfNgxUrlHelper.createUrl(
          idApiConfig.baseUrl,
          idApiConfig.endpoints.PUT_USER_ROLES.path,
          {
            userUuid,
          },
        ).toString(), putUserRolesRequestDto)
      .pipe(
        map((r) => {
          return putUserRolesResponseMapper(r);
        }),
      );
  }

  patchUserPassword(userUuid: string, patchUserPasswordRequestDto: PatchUserPasswordRequestDto): Observable<PatchUserPasswordFeedback> {
    return this.#httpClient.patch<void>(
      FfNgxUrlHelper.createUrl(
        idApiConfig.baseUrl,
        idApiConfig.endpoints.PATCH_USER_PASSWORD.path,
        {
          userUuid,
        },
      ).toString(),
      patchUserPasswordRequestDto,
    ).pipe(
      catchError((e) => {
        let message = undefined;

        if (e.error.error === 'VALIDATION_ERROR') {
          message = e.error.violations[0].errorName.toUpperCase();
        } else if (e.error.error === 'WRONG_PASSWORD') {
          message = 'WRONG_PASSWORD';
        } else {
          throw e;
        }

        return of({
          success: false,
          message,
        });
      }),
      map((r) => {
        return !!r ? r : {
          success: true,
        };
      }),
    );
  }

  searchDevelopers(query: string, limit = 10): Observable<GetUsersResponseDto> {
    return this.#httpClient.get<GetUsersResponseDto>(
      FfNgxUrlHelper.createUrl(
        idApiConfig.baseUrl,
        idApiConfig.endpoints.GET_USERS.path,
        null,
        {
          query,
          role: 'ROLE_DEVELOPER',
          limit,
        },
      ).toString(),
    );
  }

  searchTestUsers(query: string, limit = 10): Observable<GetUsersResponseDto> {
    return this.#httpClient.get<GetUsersResponseDto>(
      FfNgxUrlHelper.createUrl(
        idApiConfig.baseUrl,
        idApiConfig.endpoints.GET_USERS.path,
        null,
        {
          query,
          limit,
        },
      ).toString(),
    );
  }
}
