import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { NgxPaginationModule } from 'ngx-pagination';
import {
  FfNgxAutocompleteComponent,
  FfNgxAutocompleteTriggerDirective,
  FfNgxButtonComponent,
  FfNgxFormFieldComponent,
  FfNgxFormFieldErrorComponent,
  FfNgxIconComponent,
  FfNgxInputContainerComponent,
  FfNgxInputContainerSuffixDirective,
  FfNgxInputDirective,
  FfNgxLoadingIndicatorComponent,
  FfNgxOptionComponent,
} from '@fagforbundet/ngx-components';
import { ParentLinkComponent } from '@shared/components/parent-link/parent-link.component';
import {
  CorsUrlListCorsUrlEntryComponent,
} from '@shared/components/cors-url-list-cors-url-entry/cors-url-list-cors-url-entry.component';
import {
  ClientListClientEntryComponent,
} from '@shared/components/client-list-client-entry/client-list-client-entry.component';
import { UserPasswordFormComponent } from '@shared/components/user-password-form/user-password-form.component';

@NgModule({
  imports: [
    A11yModule,
    CommonModule,
    CoreModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    RouterModule,
    ParentLinkComponent,
    FfNgxButtonComponent,
    FfNgxIconComponent,
    FfNgxLoadingIndicatorComponent,
    CorsUrlListCorsUrlEntryComponent,
    FfNgxFormFieldComponent,
    FfNgxFormFieldErrorComponent,
    FfNgxInputContainerComponent,
    FfNgxInputDirective,
    FfNgxAutocompleteComponent,
    FfNgxAutocompleteTriggerDirective,
    FfNgxInputContainerSuffixDirective,
    FfNgxOptionComponent,
    ClientListClientEntryComponent,
    UserPasswordFormComponent,
  ],
  exports: [
    CommonModule,
    NgxPaginationModule,
    ReactiveFormsModule,
  ],
})
export class SharedModule {
}
