import { GetUsersResponseDto } from '@core/dtos/id-api/users/get-users.response-dto';
import { PageableAdminListUsers } from '@core/models/user/pageable-admin-list-users';
import { RoleHelper } from '@core/helpers/role.helper';

export function getUsersPageableAdminListUsersResponseMapper(getUsersResponseDto: GetUsersResponseDto): PageableAdminListUsers {
  if (!getUsersResponseDto) {
    return {
      users: [],
      __pagination: {
        count: 0,
      },
    };
  }

  return {
    users: getUsersResponseDto.users.map((u) => {
      return {
        uuid: u.uuid,
        firstName: u.firstName,
        lastName: u.lastName,
        testUser: u.isTestUser,
        isAnyAdmin: RoleHelper.isAnyAdmin(u),
        latestLoginSession: !u.latestLoginSession ? undefined : {
          uuid: u.latestLoginSession.uuid,
          createdAt: new Date(u.latestLoginSession.createdAt),
          status: u.latestLoginSession.status,
        },
      };
    }),
    __pagination: {
      count: getUsersResponseDto.count,
    },
  };
}
