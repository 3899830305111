import { ChangeDetectionStrategy, Component, inject, input, OnInit, output, signal } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { PendingInformationUpdates } from '@core/entities/login/pending-information-updates.entity';
import { UserFeedbackEntity } from '@core/entities/user/user-feedback.entity';
import { UserService } from '@core/services/user/user.service';
import { finalize } from 'rxjs';
import { User } from '@core/models/user/user';
import {
  FfNgxButtonComponent,
  FfNgxFormFieldComponent,
  FfNgxFormFieldErrorComponent,
  FfNgxInputContainerComponent,
  FfNgxInputContainerSuffixDirective,
  FfNgxInputDirective,
  FfNgxPasswordRevealComponent,
} from '@fagforbundet/ngx-components';
import { UserPasswordForm } from '@shared/components/user-password-form/form-types/user-password.form';
import { createUserPasswordForm } from '@shared/components/user-password-form/helpers/create-user-password-form';
import { patchUserPasswordRequestMapper } from '@core/mappers/response/id-api/users/patch-user-password.request-mapper';
import { PatchUserPasswordFeedback } from '@core/models/user/patch-user-password.feedback';
import { UserPasswordFormApiErrors } from '@core/enums/user-password-form-api-errors';
import { UserFeedbackTypesEnum } from '@core/enums/user-feedback-types.enum';
import { FeedbackMessagesComponent } from '@shared/components/feedback-messages/feedback-messages.component';

@Component({
  selector: 'app-user-password-form',
  templateUrl: './user-password-form.component.html',
  styleUrls: ['./user-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FfNgxFormFieldErrorComponent,
    FfNgxInputContainerComponent,
    FfNgxFormFieldComponent,
    FfNgxInputDirective,
    ReactiveFormsModule,
    FfNgxPasswordRevealComponent,
    FfNgxButtonComponent,
    FfNgxInputContainerSuffixDirective,
    FeedbackMessagesComponent,
  ],
})
export class UserPasswordFormComponent implements OnInit {
  readonly #userService = inject(UserService);

  pendingInfoUpdates = input<PendingInformationUpdates>();

  /**
   * Fires when password has been saved
   */
  saved = output<boolean>();

  submitIcon = input<string>();

  user = input.required<User>();

  feedbackMessages = signal<Array<UserFeedbackEntity>>([]);
  passwordForm: FormGroup<UserPasswordForm>;

  expandedError = signal(false);
  processing = signal(false);

  ngOnInit() {
    this.passwordForm = createUserPasswordForm(this.user().passwordSet);
  }

  savePassword() {
    this.passwordForm.updateValueAndValidity();

    if (this.passwordForm.invalid) {
      this.passwordForm.markAllAsTouched();
      return;
    }

    this.processing.set(true);

    this.#userService
      .patchUserPassword(
        this.user().uuid,
        patchUserPasswordRequestMapper(
          this.passwordForm.controls.password.value,
          this.passwordForm.controls.repeatPassword.value,
          this.passwordForm.controls.currentPassword?.value,
        ),
      )
      .pipe(
        finalize(() => {
          this.processing.set(false);
        }),
      )
      .subscribe((r) => {
        if (!r.success) {
          this.#handleFeedback(r);
          return;
        }

        // This emit is crucial, it fires callback for SSO + OAuth
        this.saved.emit(true);

        this.feedbackMessages.update((fe) => {
          return [
            ...fe,
            new UserFeedbackEntity('Lagret', UserFeedbackTypesEnum.INFORMATIVE),
          ];
        });

        this.passwordForm = createUserPasswordForm(true);
      });
  }

  #handleFeedback(r: PatchUserPasswordFeedback) {
    switch (r.message) {
      case UserPasswordFormApiErrors.COMPROMISED_PASSWORD_ERROR:
        this.passwordForm.controls.password.setErrors({
          ...this.passwordForm.controls.password.errors,
          compromised: true,
        });
        this.expandedError.set(true);
        break;
      case UserPasswordFormApiErrors.NOT_IDENTICAL_ERROR:
        this.passwordForm.controls.repeatPassword.setErrors({
          ...this.passwordForm.controls.repeatPassword.errors,
          not_identical: true,
        });
        break;
      case UserPasswordFormApiErrors.TOO_SHORT_ERROR:
        this.passwordForm.controls.password.setErrors({
          ...this.passwordForm.controls.password.errors,
          too_short: true,
        });
        this.expandedError.set(false);
        break;
      case UserPasswordFormApiErrors.WRONG_PASSWORD:
        this.passwordForm.controls.currentPassword.setErrors({
          ...this.passwordForm.controls.currentPassword.errors,
          wrong_password: true,
        });
        this.expandedError.set(false);
        break;
    }

    this.passwordForm.markAllAsTouched();
  }
}
