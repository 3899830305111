import { AdminDetailUser } from '@core/models/user/admin-detail-user';
import { PutUserResponseDto } from '@core/dtos/id-api/users/put-user.response-dto';

export function putUserResponseMapper(putUserResponseDto: PutUserResponseDto): AdminDetailUser {
  return {
    uuid: putUserResponseDto.user.uuid,
    firstName: putUserResponseDto.user.firstName,
    lastName: putUserResponseDto.user.lastName,
    testUser: putUserResponseDto.user.isTestUser,
    emailAddresses: [],
    phoneNumbers: [],
    postalAddresses: [],
    roles: [],
  };
}
