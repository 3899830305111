import { Component, input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FfNgxIconComponent } from '@fagforbundet/ngx-components';
import { OAuthClient } from '@core/models/oidc/client/oauth-client';
import { SsoClient } from '@core/models/oidc/client/sso-client';

@Component({
    selector: 'app-client-list-client-entry',
    templateUrl: './client-list-client-entry.component.html',
    styleUrls: ['./client-list-client-entry.component.scss'],
    imports: [
        DatePipe,
        FfNgxIconComponent,
    ],
    host: {
        class: 'client ff-card',
    }
})
export class ClientListClientEntryComponent {
  client = input.required<OAuthClient | SsoClient>();
}
