import { Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FfNgxIconComponent } from '@fagforbundet/ngx-components';

@Component({
    selector: 'app-parent-link',
    templateUrl: './parent-link.component.html',
    styleUrls: ['./parent-link.component.scss'],
    imports: [
        RouterLink,
        FfNgxIconComponent,
    ]
})
export class ParentLinkComponent {
  routerLink = input.required<any[]>();
}
