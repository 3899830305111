import { AdminDetailUser } from '@core/models/user/admin-detail-user';
import { PostUserResponseDto } from '@core/dtos/id-api/users/post-user.response-dto';

export function postUserResponseMapper(postUserResponseDto: PostUserResponseDto): AdminDetailUser {
  return {
    uuid: postUserResponseDto.user.uuid,
    firstName: postUserResponseDto.user.firstName,
    lastName: postUserResponseDto.user.lastName,
    testUser: postUserResponseDto.user.isTestUser,
    emailAddresses: [],
    phoneNumbers: [],
    postalAddresses: [],
    roles: [],
  };
}
