import { EnvironmentType } from '@environments/assets/environment-type';
import { idApiTargets } from '@config/apis/id/id-api-targets';
import { AvailableApiName } from '@environments/assets/available-api-name.type';
import { EnvironmentInterface } from '@environments/assets/environment.interface';

export const environment = {
  type: EnvironmentType.BUILD,
  apis: {
    id: idApiTargets.build,
  } satisfies Record<AvailableApiName, EnvironmentInterface>,
  debug: {
    authBundle: false,
    loadingScreen: false,
    oidc: false,
    requestBundle: false,
    router: false,
    storageBundle: false,
  },
  logoutUrl: 'https://fagforbundet.no',
  frontEnd: {
    baseUrl: 'https://id.fagforbundet.build',
    verifyEmailAddressUrl: 'https://id.fagforbundet.build/verification/email',
    host: 'id.fagforbundet.build',
    path: '/',
    port: 443,
    protocol: 'https',
    userInfoUpdateForm: {
      debugSections: {
        namesAndAddress: false,
        bankAccountNumber: false,
        phoneNumbers: false,
        emailAddresses: false,
      }
    },
  },
  minSideBaseUrl: 'https://minside.fagforbundet.build',
};
