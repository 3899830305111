import { environment } from '@environments/environment';
import { createApiConfiguration } from '@fagforbundet/ngx-components';
import { IdApiEndpoints } from '@config/apis/id/id-api-endpoints';

const idApiConfig = createApiConfiguration<typeof IdApiEndpoints>({
  baseUrl: environment.apis.id.baseUrl,
  endpoints: IdApiEndpoints,
});

export default idApiConfig;
