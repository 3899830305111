<div class="header">
  @let c = client();
  <h2 class="title">{{ c.name }}</h2>
  @if ('oauth' === c.type) {
    @for (grant of c.allowedGrants; track grant.uuid) {
      @if (grant.icon) {
        <img [src]="grant.icon"
             title="Klienten bruker {{ grant.name }} grant"
             alt="Klienten bruker {{ grant.name }} grant"
             class="grant-icon" role="presentation"
        />
      }
    }
  }
</div>
<div class="details">
  <div class="description">
    <img src="assets/icons/document_a_24.svg" alt="Ikon" role="presentation" />
    <span>{{ c.description }}</span>
  </div>
  @if (c.hasOwnProperty('lastUsedAt')) {
    <div class="last-used-at">
      <ff-ngx-icon name="16x16/clock" [size]="16" stroke="#3c3c3b" />
      {{ c['lastUsedAt'] ? ('Sist brukt ' + (c['lastUsedAt'] | date: 'dd.MM.yyyy HH:mm')) : 'Aldri tatt i bruk' }}
    </div>
  }
  <div class="bottom-line">
    <div class="client-type">
      @if (c.type === 'sso') {
        <img src="assets/icons/key.svg" alt="Klienttypeikon" role="presentation" />
      }
      @if (c.type === 'oauth') {
        <img src="assets/icons/openid.svg" alt="Klienttypeikon" role="presentation" />
      }
      <span>{{ c.type === 'oauth' ? 'OAuth' : 'SSO' }}</span>
    </div>
    <div class="client-active">
      @if (c.active) {
        <ff-ngx-icon name="16x16/verified" [size]="16" stroke="#3c3c3b" />
      } @else {
        <ff-ngx-icon name="16x16/close" [size]="16" stroke="#3c3c3b" />
      }
      <span>{{ c.active ? 'Aktiv' : 'Inaktiv' }}</span>
    </div>
    <div class="client-dev-mode">
      @if (c.devMode) {
        <img src="assets/icons/person_hardhat.svg" alt="Ikon" role="presentation" />
        <span>DevMode</span>
      }
    </div>
  </div>
</div>
