import { Roles } from '@core/models/user/roles';

export class RoleHelper {
  static isAnyAdmin(user: { roles: { role: string }[] }): boolean {
    return (
      RoleHelper.hasRole(user, Roles.ROLE_APP_ADMIN) ||
      RoleHelper.hasRole(user, Roles.ROLE_SYSTEM_ADMIN) ||
      RoleHelper.hasRole(user, Roles.ROLE_USER_ADMIN)
    );
  }

  static isAppAdmin(user: { roles: { role: string }[] }): boolean {
    return RoleHelper.hasRole(user, Roles.ROLE_APP_ADMIN);
  }

  static isSysAdmin(user: { roles: { role: string }[] }): boolean {
    return RoleHelper.hasRole(user, Roles.ROLE_SYSTEM_ADMIN);
  }

  static isUserAdmin(user: { roles: { role: string }[] }): boolean {
    return RoleHelper.hasRole(user, Roles.ROLE_USER_ADMIN);
  }

  static hasRole(user: { roles: { role: string }[] }, role: Roles): boolean {
    if (!user) {
      return false;
    }

    for (const r of user.roles) {
      if (r.role === role) {
        return true;
      }
    }

    return false;
  }
}
